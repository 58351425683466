.logo-block {
  display: flex;
  align-items: center;
  gap: 10px;
  .logo-image {
    width: 60px;
  }
  .logo-text-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
      color: var(--red);
      font-weight: 500;
    }
    .logo-text {
      font-size: 18px;
    }
    .logo-subtext {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 400;
    }
  }
}
