.list_style-phone {
  display: flex;
  gap: 15px;
  .item_style {
    .link_style {
      color: var(--white);
      font-weight: 500;
      span {
        font-size: 13px;
        color: var(--white);
      }
    }
  }
}
