.tarifsPage_section {
  .mobile_tarifs {
    display: none;
  }
  .desktop_tarifs {
    display: block;
  }
}

@media (max-width: 768px) {
  .tarifsPage_section {
    .mobile_tarifs {
      display: block;
    }
    .desktop_tarifs {
      display: none;
    }
  }
}
