.list_style-linkList {
  display: flex;
  gap: 15px;
  .item_style {
    .link_style {
      text-transform: uppercase;
      font-size: 13px;
      color: var(--white);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.header-list_style-linkList {
  display: flex;
  gap: 15px;
  .item_style {
    padding: 10px 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover > .link_style {
      color: var(--blue);
    }

    .link_style {
      color: var(--gray);
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      white-space: nowrap;
    }
    .link_style.active {
      box-shadow: 0 1px 4px rgba(255, 255, 255, 0.3), -5px 0 20px -5px rgba(222, 222, 222, 0.8),
        5px 0 20px -5px rgba(222, 222, 222, 0.8);
      color: var(--blue) !important;
      padding: 10px 10px;
    }
  }
}

.mobile_header-list_style-linkList {
  .item_style {
    text-align: center;
    padding: 10px 5px;
    width: 100%;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.03);
    &:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.03);
    }
    .link_style {
      padding: 10px 5px;
      color: white;
      font-size: 14px;
    }
    .link_style.active {
      color: #0077ff;
    }
  }
}
