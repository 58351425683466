:root {
  --white: #fff;
  --bg-color-light-gray: #dbeefc;
  --blue_1: #005596;
  --light-blue: #1f7dbb;
  --yellow: #eecc21;
  --light-gray: #f7f7f7;
  --red: #cc0404;
  --orange: #f7906b;
  --text: #767d93;
  --gray: #444444;

  --blue: #0e0620;
  --green: #2ccf6d;
}
