.section_wrapper {
  // background-image: url('../../../Assets/Images/wave.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .wrapper {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .img {
      max-width: 300px;
      img {
        width: 100%;
      }
    }

    .content {
      .inner {
        .title {
          font-size: 54px;
          font-weight: 500;
          line-height: 70px;
          color: #292a34;
          text-transform: uppercase;
        }
        .text {
          font-size: 20px;
          max-width: 500px;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section_wrapper {
    .wrapper {
      padding: 20px 0;
      flex-wrap: wrap;
      gap: 20px;
      text-align: center;

      .content {
        .inner {
          .title {
            font-size: 36px;
            line-height: 40px;
          }
          .text {
            font-size: 16px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
