header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .header_top {
    background-color: #0177ff;
    padding: 15px 0;
    transition: all 0.3s ease;
    .headerTop_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
    }
  }
  .header {
    background-color: var(--white);
    padding: 10px 0;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(34, 60, 80, 0.19);
    -moz-box-shadow: 0px 4px 5px 0px rgba(34, 60, 80, 0.19);
    box-shadow: 0px 4px 5px 0px rgba(34, 60, 80, 0.19);

    .header_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .header_mobile {
    display: none;
    .header_top {
      display: block;
    }
    .header_bottom {
      background-color: var(--white);
      padding: 8px 0;
      transition: all 0.3s ease;
      -webkit-box-shadow: 0px 4px 5px 0px rgba(34, 60, 80, 0.19);
      -moz-box-shadow: 0px 4px 5px 0px rgba(34, 60, 80, 0.19);
      box-shadow: 0px 4px 5px 0px rgba(34, 60, 80, 0.19);
      .header_bottom_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .burger_menu {
          i {
            font-size: 24px;
            color: var(--blue);
          }
        }
        .header_mobile_menu_list {
          display: block;
          position: fixed;
          padding-bottom: 20px;
          background-color: #191919;
          top: 0;
          right: -250px;
          width: 250px;
          height: 100%;
          overflow: auto;
          z-index: 1005;
          transition: right 0.3s ease;
          .close_btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 60px;
            padding: 0px 15px;
            i {
              font-size: 24px;
              color: #a6a6a6;
            }
          }
        }
        .menu_open {
          right: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  header {
    .header_top {
      display: none;
    }
    .header {
      display: none;
    }
    .header_mobile {
      display: block;
    }
  }
}
