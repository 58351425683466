.title_wrapper {
  padding: 50px 0px;
  .wrapper {
    margin-top: 20px;
    text-align: center;
    h4 {
      font-size: 48px;
      line-height: 52px;
      color: #292a34;
      font-weight: 500;
      margin-bottom: 10px;
    }
    p {
      font-size: 20px;
      line-height: 27px;
      color: #4f5059;
      font-weight: 400;
      margin: 0 auto;
      width: 700px;
    }
  }
}

@media (max-width: 768px) {
  .title_wrapper {
    .wrapper {
      h4 {
        font-size: 32px;
        line-height: 34px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
        width: 100%;
      }
    }
  }
}
