.tarif_bg_color_1 {
  background-color: #f7de5da6 !important;
  text-align: center;
}

.tarif_bg_color_2 {
  background-color: #1f7dbbc2 !important;
  text-align: center;
}

.tarif_bg_color_3 {
  background-color: #f7906bc4 !important;
  text-align: center;
}
