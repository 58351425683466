.tarifs_wrapper {
  padding: 50px 50px;
  // background-color: #0077ff;
  .wrapper {
    .tarifs {
      display: flex;
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;
      .tarif_item {
        display: grid;
        grid-template-rows: 0fr 0fr 0fr;
        width: 340px;
        max-width: 340px;
        min-width: 280px;
        padding: 20px 10px;
        background-color: #fff;
        color: #000;
        border-radius: 25px;
        text-align: center;
        position: relative;
        box-shadow: 0px 26px 34px rgba(0, 0, 0, 0.09);
        border: 1px solid var(--yellow);
        h4 {
          color: var(--red);
        }
        .tarif_img {
          width: 180px;
          max-width: 180px;
          margin: 0 auto;
          padding-bottom: 30px;
          img {
            width: 100%;
          }
        }
        .tarif_price {
          font-size: 24px;
          font-weight: 600;
          color: var(--blue);
          margin-top: 10px;
        }
        .variant_block {
          padding-top: 15px;

          .tarif_variant_title {
            font-size: 18px;
            text-align: start;
          }
          .variant_list {
            display: block;
            .variant_item {
              margin-top: 8px;
              display: flex;
              gap: 10px;
              align-items: center;
              margin-left: 10px;
              .iconCircleCheck {
                color: var(--green);
              }
              .iconXmark {
                color: var(--red);
              }
              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }

        .tarif_btn {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 25px;
          @media (max-width: 425px) {
            padding: 0px;
          }
        }
      }
    }
  }
}
