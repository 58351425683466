.module_wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 50px 47px rgba(0, 0, 0, 0.02);
  border-radius: 0 0 50px 50px;
  margin-top: -1px;
  .wrapper {
    padding: 100px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    .image_block {
      flex: 1;
      width: 50%;
      img {
        max-width: 600px;
        width: 100%;
      }
    }

    .content {
      flex: 1;
      width: 50%;
      .headline_text {
        font-size: 20px;
        line-height: 24px;
        color: #0077ff;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .title {
        font-size: 54px;
        line-height: 70px;
        color: #292a34;
        margin: 70px 0 80px;
        font-weight: 700;
      }
      .text {
        font-size: 20px;
        line-height: 27px;
        color: #4f5059;
        font-weight: 400;
      }
      div {
        margin-top: 50px;
        .formBtn {
          border: 1px solid #0077ff;
          box-shadow: 0px 15px 25px rgba(0, 119, 255, 0.15);
          outline: none;
          border-radius: 24px;
          background-color: #0077ff;
          padding: 10px 30px;
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: #fff;
            color: #0077ff;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .module_wrapper {
    .wrapper {
      padding: 50px 0;
      flex-wrap: wrap;
      .image_block {
        width: 100%;
      }
      .content {
        width: 100%;
        .headline_text {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0px;
        }
        .title {
          font-size: 34px;
          line-height: 40px;
          margin: 30px 0 40px;
        }
        .text {
          font-size: 16px;
          line-height: 24px;
        }
        div {
          margin-top: 30px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    display: block !important;
    text-align: center;
    .image_block {
      max-width: 300px !important;
      width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .content {
      .headline_text {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0px;
      }
      .title {
        font-size: 26px;
        line-height: 30px;
        margin: 25px 0 30px;
      }
      .text {
        font-size: 14px;
        line-height: 20px;
      }
      div {
        margin-top: 30px;
      }
    }
  }
}
