.price_wrapper {
  padding: 100px 0;

  background-color: #0077ff;
  margin-top: 7px;
  margin-bottom: 20px;
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 26px 34px rgba(0, 0, 0, 0.09);
  .wrapper {
    display: block;
    .headline {
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-size: 26px;
        line-height: 30px;
        font-weight: 600;
        color: #f79827;
      }
      p {
        font-size: 36px;
        line-height: 45px;
        font-weight: 500;
        color: #fff;
      }
    }
    .items {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 150px;
      .item {
        display: grid;
        grid-template-rows: 0fr 0fr 0fr;
        width: 340px;
        max-width: 340px;
        // padding-top: 110px !important;
        padding: 30px 25px;
        background-color: #fff;
        color: #000;
        border-radius: 25px;
        text-align: center;
        position: relative;
        .img {
          width: 180px;
          max-width: 180px;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }

        h4 {
          font-size: 28px;
          font-weight: 500;
          line-height: 38px;
        }
        .subtitle {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          margin-top: 5px;
        }
        .price {
          font-size: 38px;
          font-weight: 500;
          line-height: 42px;
          margin-bottom: 10px;
          margin-top: 30px;
        }
        .prod_description {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .btn {
          margin-top: 50px;
          align-self: end;
          button {
            outline: none;
            border: 1px solid #f79827;
            background-color: #f79827;
            color: #fff;
            padding: 10px 30px;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            border-radius: 20px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: #fff;
              color: #f79827;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .price_wrapper {
    padding: 50px 0;
    .wrapper {
      .headline {
        h3 {
          font-size: 22px;
          line-height: 26px;
        }
        p {
          font-size: 30px;
          line-height: 34px;
        }
      }
      .items {
        margin-top: 95px;
        .item {
          grid-template-rows: none;

          h4 {
            font-size: 22px;
            line-height: 26px;
          }
          .subtitle {
            font-size: 14px;
            line-height: 18px;
          }
          .price {
            font-size: 26px;
            line-height: 30px;
          }
          .prod_description {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .price_wrapper {
    .wrapper {
      .items {
        margin-top: 50px;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
      }
    }
  }
}

@media (max-width: 715px) {
  .price_wrapper {
    .wrapper {
      .headline {
        h3 {
          font-size: 18px;
          line-height: 22px;
        }
        p {
          font-size: 20px;
          line-height: 24px;
        }
      }
      .items {
        margin-top: 50px;
        flex-wrap: wrap;
        justify-content: center;
        gap: 35px;
      }
    }
  }
}
