* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.content {
  flex: 1 0 auto;
  padding-top: 130px;
}

@media (max-width: 1024px) {
  .content {
    padding-top: 75px;
  }
}

.footer {
  flex: 0 0 auto;
}

.container {
  max-width: 1420px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 10px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.flex-item {
  flex: 1;
}

.flex-item:nth-child(1) {
  order: 2; /* Меняем порядок первого элемента на 2 */
}

.flex-item:nth-child(2) {
  order: 1; /* Меняем порядок второго элемента на 1 */
}

@media (max-width: 768px) {
  .flex-item {
    flex: 0 1 auto;
  }
  .flex-item:nth-child(2) {
    order: 2; /* Меняем порядок первого элемента на 2 */
  }
}

.text-align-end {
  text-align: end;
}

a {
  text-decoration: none;
}

.yellow-btn {
  cursor: pointer;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  background-color: var(--yellow);
}

.btn_block {
  padding: 10px 0px;
  margin-top: 20px;
}

.red-btn {
  padding: 10px 20px;
  background-color: var(--red);
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #f52929;
  }
}
.socail_links {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  gap: 15px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    li {
      max-width: 60px;
      max-height: 60px;
      transition: transform 0.2s;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }
      .social_link {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .arrow_box {
        position: relative;
        background: #88b7d5;
        border: 1px solid #c2e1f5;
        margin-bottom: 10px;
        h1 {
          color: #ddf8c6;
          text-align: center;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          text-transform: uppercase;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
          margin: 0;
          padding: 5px;
        }
      }
      .arrow_box:after,
      .arrow_box:before {
        top: 100%;
        left: 12%;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      .arrow_box:after {
        border-color: rgba(136, 183, 213, 0);
        border-top-color: #88b7d5;
        border-width: 10px;
      }
      .arrow_box:before {
        border-color: rgba(194, 225, 245, 0);
        border-top-color: #c2e1f5;
        border-width: 10px;
      }
    }
  }
}

.scroll__top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999;
  .scroll__top-btn {
    padding: 10px;
    border-radius: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    i {
      font-size: 36px;
      color: #0177ff;
      transition: all 0.3s ease;
    }
    &:hover {
      background-color: #0177ff;
      i {
        color: #fff;
      }
    }
  }
}

.FaRegCircleXmark {
  color: var(--red);
}

.FaRegCircleCheck {
  color: #00ff66;
}

th {
  font-style: italic;
}
