.footer_wrapper {
  background-color: var(--yellow);
  box-shadow: 0px 50px 47px rgba(0, 0, 0, 0.1);
  border-radius: 50px 50px 0 0;
  padding: 50px 0;
  margin: 10px;
  .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    .img {
      max-width: 90px;
      img {
        width: 100%;
      }
    }
    .copyright {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-align: center;
    }
    .social_links {
      display: flex;
      gap: 20px;
      align-items: center;
      li {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 425px) {
  .footer_wrapper {
    .wrapper {
      .copyright {
        font-size: 14px;
        line-height: 18px;
      }
      .social_links {
        display: flex;
        gap: 20px;
        align-items: center;
        li {
          cursor: pointer;
        }
      }
    }
  }
}
