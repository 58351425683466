.list_wrapper {
  padding-bottom: 50px;
  background-color: #fff;
  box-shadow: 0px 50px 47px rgba(0, 0, 0, 0.02);
  border-radius: 0 0 50px 50px;
  margin-top: -1px;
  .wrapper {
    .list_group {
      .list_item {
        padding: 20px 10px;
        font-size: 18px;
        strong {
          font-weight: 600;
        }
      }
    }
  }
}
