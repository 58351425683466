.paybox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  h1 {
    font-size: 18px;
    color: #2bb0eb;
    margin-top: 20px;
  }
  div {
    margin-bottom: 20px;
  }
}
