.wrapper_about {
  padding: 50px 0;
  .img {
    max-width: 500px;
    img {
      width: 100%;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    .item_1 {
      flex: 1;
      width: 50%;
      .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .company_name {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
        }
        .link {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
    .item_2 {
      flex: 1;
      width: 50%;
    }
  }
}

@media (max-width: 768px) {
  .wrapper_about {
    .wrapper {
      flex-direction: column;
      gap: 30px;
      .item_1 {
        width: 100%;
      }
      .item_2 {
        width: 100%;
      }
    }
  }
}
